.upload-transcript-wrapper {
  border: 1px solid rgba(50, 51, 55, 0.1);
  box-shadow: 0px 8px 16px rgba(41, 40, 45, 0.04);
  border-radius: 32px;
  margin: 1rem;
  padding: 2rem;
  height: calc(100% - 2rem);
}

.upload-transcript-heading {
  font-size: 20px;
  font-weight: 600;
}

.upload-transcript-form {
  margin-top: 20px;
}

.upload-transcript-form-input-div {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.upload-transcript-form-input-div-label {
  margin-bottom: 10px;
}

.upload-transcript-form-choose-file {
  margin-top: 20px;
  display: flex;
  flex-direction: column-reverse;
}

.upload-transcript-form-choose-file-button {
  height: 3rem;
  width: 8rem;
  background-color: #5f6ce1;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px;
  border-radius: 2rem;
  cursor: pointer;
}

.upload-transcript-form-choose-file-content {
  margin-bottom: 10px;
}

.upload-transcript-form-upload-button {
  margin-top: 20px;

  height: 3rem;
  width: 12rem;
  background-color: #f1d07c;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  cursor: pointer;
}

.upload-transcript-form-upload-button-uploading {
  margin-top: 20px;

  height: 3rem;
  width: 12rem;
  background-color: #c0c0c0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  cursor: pointer;
}
