/**************************  Auth Page Left Side styling **********************/

.auth-wrapper {
  display: flex;
  width: 100%;
  height: 100vh;
}

.auth-promotion {
  width: 65%;
  flex-direction: column;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-promotion-app-logo {
  width: 35%;
  margin-bottom: 30px;
}

.auth-promotion-logo {
  width: 90%;
  z-index: 0;
}

.auth-form-wrapper {
  width: 35%;
  flex-direction: column;
  background: #f5f5f5;
}

.auth-form-verify-org {
  width: 100%;
  flex-direction: column;
  padding-left: 100px;
  padding-right: 100px;
  margin-bottom: 50px;
}

.auth-form-verify-org-field-wrapper {
  width: 100%;
  display: flex;
  /* margin: 10px; */
}

.auth-form-verify-org-field-wrapper div {
  width: 100%;
  display: flex;
}

.auth-form-verify-alert {
  margin-bottom: 20px;
}

.auth-form-verify-org-field-wrapper .amplfiy-alert {
  flex-direction: column;
}

.auth-promotion-auth-page {
  z-index: 1;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  user-select: none;
  color: #b5b5b5;
}

.auth-promotion-auth-page span {
  color: #5f6ce1;
  cursor: pointer;
}

/**************************  Auth AWS Form styling **********************/
.amplify-tabs {
  display: none !important;
}

:root {
  /* --amplify-font-sizes-large: 1.25rem;
    --amplify-font-sizes-medium: 1.5rem !important;
    --amplify-font-sizes-small: 1.25rem !important; */

  --amplify-colors-background-primary: #f5f5f5;
  --amplify-colors-background-secondary: #f5f5f5;

  --amplify-colors-brand-primary-100: #505fe6 !important;
  --amplify-colors-brand-primary-90: #5563dc !important;
  --amplify-colors-brand-primary-80: #6b76da !important;
  --amplify-colors-brand-primary-20: white !important;
  --amplify-colors-brand-primary-10: white !important;
}

.auth-from {
  width: 40%;
}
/* [data-amplify-authenticator] {
    width: 100% !important;
  } */

/* [data-amplify-authenticator] [data-amplify-container] {
    margin-top: -100px !important;
    width: 30% !important;
  } */

[data-amplify-authenticator] [data-amplify-router] {
  box-shadow: none !important;
  border: none !important;
}

.amplify-input {
  font-style: normal;
  font-weight: 400;
  height: 55px !important;
  background-color: rgba(255, 255, 255, 0.3);
  /* border: none !important; */
  margin-bottom: 10px !important;
}

.amplify-field-group__outer-end .amplify-field-group__control:not(:focus),
.amplify-field-group__outer-end .amplify-field-group__control:focus {
  background-color: rgba(255, 255, 255, 0.3);
  /* border: none !important; */
  height: 55px !important;
  margin-bottom: 20px !important;
}

.amplify-button[data-variation="primary"] {
  font-style: normal;
  font-weight: 600 !important;
  color: rgb(255, 255, 255) !important;
  border-radius: 16px !important;
  height: 55px !important;
}

.amplify-button[data-variation="link"] {
  color: black !important;
  background-color: #f5f5f5;
  box-shadow: none !important;
}

h3.amplify-heading {
  font-weight: 700;
  font-size: var(--amplify-components-heading-3-font-size);
  margin-bottom: 10px !important;
}
/*******************************************************************/

.auth-form-logo {
  height: 5rem;
}

.auth-form-logo-wrapper-left {
  display: flex;
  width: 100%;
  padding-left: 100px;
  margin-bottom: 2rem;
}

/*******************************************************************/

.free-trial {
  margin-top: 3rem;
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.free-trial-text {
  color: var(--button-primary, #282860);
  /* H3 */
  font-family: Nunito;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.5rem;
}

.free-trial-button {
  border-radius: 2rem;
  background: #5f6ce1;
  box-shadow: 0px 4px 40px -10px rgba(106, 106, 106, 0.2);
}

.organisation-auth-wrappper {
  width: 100%;
}

.organisation-auth-form-verify-org-field-wrapper {
  width: 100%;
  margin: 10px;
}

.bottom-status {
  width: 100px;
  height: 5px;
  border-radius: 10rem;
  border: none;
  color: #5f6ce1;
}

.check-invite-text {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  color: #282860;
  /* font-family: Nunito; */
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem; /* 175% */
}

.division-line {
  width: 40%;
  height: 5px;
}

.free-trial-bookmark {
  height: 5rem;
  top: 0;
  position: fixed;
  right: 3rem;
}
