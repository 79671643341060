.app {
  display: flex;
  max-width: 1800px;
  height: 100vh;
  width: 100vw;
}

.view {
  border: 2px solid red;
  /* background-color: bisque; */
}

.main-content-wrapper {
  width: calc(100vw - 4rem);
  height: 100vh;
}

.heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 180%;
  /* or 36px */

  display: flex;
  align-items: center;

  color: #282860;
}

.sub-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 180%;

  display: flex;
  align-items: center;

  color: #282860;
  /* color: #8282fd; */
}

.sub-heading-white {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 180%;

  display: flex;
  align-items: center;

  color: white;
}

.info-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 180%;
  /* or 29px */

  display: flex;
  align-items: center;

  color: #575757;
}
.info-text-white {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 180%;
  /* or 29px */

  display: flex;
  align-items: center;

  color: #eee;
}

.info-text-black {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 180%;
  /* or 29px */

  display: flex;
  align-items: center;

  color: #000;
}

.info-text-medium {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 180%;
  /* or 29px */

  display: flex;
  align-items: center;

  color: #575757;
}

.info-text-medium-bold {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 180%;
  /* or 29px */

  display: flex;
  align-items: center;

  color: #999;
}

.profile-picture {
  height: 48px;
  border-radius: 48px;
}

.profile-picture-big {
  height: 120px;
  border-radius: 1000px;
}

.orange-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 180%;
  /* or 36px */

  display: flex;
  align-items: center;

  color: #ec7440;
}

.circle-text-container {
  border-radius: 100px;
  background-color: #ec7440;
  color: white;
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.normal-input {
  width: 24rem;
  height: 2.8rem;
  border-radius: 0.8rem;
  border: 2px solid #aaa;
  padding: 0rem 0.8rem;
}

.normal-dropdown {
  width: 24rem;
  height: 2.72rem;
  border: solid 1.4px #aaa;
  border-radius: 2rem;
}
.profile-dropdown {
}
.wide-input {
  width: 100%;
  height: 2rem;
  border-radius: 0.8rem;
  border: 2px solid #aaa;
}

.red-small-button {
  height: 1.8rem;
  width: 6rem;
  background-color: #f19b7c;
  /* background-color: #b9402d; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  cursor: pointer;
}

.yellow-small-button {
  height: 1.8rem;
  width: 6rem;
  background-color: #f1d07c;
  /* background-color: #b9402d; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  cursor: pointer;
}
.yellow-small-button-border {
  padding: 4px 12px;
  background-color: white;
  border: #f1d07c 2px solid;
  /* background-color: #b9402d; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  cursor: pointer;
}
.grey-small-button {
  height: 1.8rem;
  width: 6rem;
  background-color: grey;
  /* background-color: #b9402d; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  cursor: pointer;
}

.yellow-button {
  height: 3rem;
  width: 12rem;
  background-color: #f1d07c;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  cursor: pointer;
  /* transition: 400ms all; */
}
.yellow-button:hover {
  font-weight: 500;
  /* font-size: 15px; */
}

.yellow-button-round-icon {
  width: 36px;
  height: 36px;

  font-size: 1.8rem;
  color: #575757;
  background-color: #f1d07c;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  cursor: pointer;
}

.blue-button {
  height: 3rem;
  width: 12rem;
  background-color: #5f6ce1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  cursor: pointer;
  color: white;
}
.white-button {
  height: 3rem;
  width: 12rem;
  background-color: white;
  color: #5f6ce1;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  cursor: pointer;
}

.sized-box {
  height: 2.4rem;
}
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 2.4rem;
}
.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.8rem;
}

.scroll-remove::-webkit-scrollbar {
  width: 0;
}
.scroll-thin,
.scroll-thin-vertical {
  scrollbar-width: thin;
  scrollbar-color: #cdd1f4 #ffffff;
  scrollbar-gutter: stable;
}
.scroll-thin::-webkit-scrollbar {
  width: 14px;
}

.scroll-thin::-webkit-scrollbar-track,
.scroll-thin-vertical::-webkit-scrollbar {
  background-color: transparent;
  border-radius: 100px;
  margin: 0 30px;
}

.scroll-thin::-webkit-scrollbar-thumb,
.scroll-thin-vertical::-webkit-scrollbar-thumb {
  border: 5px solid transparent;
  border-radius: 100px;
  background-color: #cdd1f4;
  background-clip: content-box;
}

.scroll-thin-vertical::-webkit-scrollbar {
  width: 16px;
}

.scroll-thin-vertical::-webkit-scrollbar-track {
  margin: 20px 0;
}

.profile-img-container {
  height: 48px;
  width: 48px;
  border-radius: 24px;
}

/* for dates selector on top right in graph and analytics pages */
.dateMode {
  color: rgba(20, 26, 69, 0.3);
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
}

.dateModeSelected {
  color: rgba(20, 26, 69);
}

.date-selector-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.date-selector-wrapper > div {
  flex: 1 0;
  text-align: center;
  font-size: 12px;
}
