.sidebar-wrapper {
  width: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sidebar-logo {
  box-sizing: content-box;
  height: 2rem;
  padding: 1rem;
  position: absolute;
  top: 0;
}

.sidebar-menu {
  background-color: transparent;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: max-content;
  padding: 5rem 0;
  justify-content: center;
  margin-top: -3rem;
  position: relative;
}

.sidebar-icon {
  height: 1.4rem;
  width: 1.4rem;
}

.sidebar-tab {
  z-index: 10000;
  height: 2.9rem;
  width: 2.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0;
  position: relative;
}

.sidebar-tab.active {
  z-index: 10000;
  background-color: #fff;
  border-radius: 1rem;
}

.sidebar-tooltip {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  padding: 0.25rem 0.5rem;
  background-color: white;
  color: #282860;
  z-index: 10000;
  border-radius: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
  white-space: nowrap;
  width: 160;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.sidebar-tab.active .sidebar-tooltip {
  border-radius: 0 20px 20px 0;
}

.sidebar-tab:hover .sidebar-tooltip {
  z-index: 10000;
  opacity: 1;
}

.sidebar-tab.active:hover{
  border-radius: 1rem 0 0 1rem;
}